















































import type { Link, Picture } from '@/inc/types'

import { defineComponent, PropType, ref } from '@vue/composition-api'

import { pushCta } from '@/inc/utils'

interface CtaMoodboard {
  title: string
  htmltext: string
  link: Link
  picture: Picture
  backgroundPicture: Picture
}

export default defineComponent({
  name: 'CtaMoodboard',
  props: {
    content: {
      type: Object as PropType<CtaMoodboard>,
      required: true,
    },
  },

  setup(props) {
    const rootElRef = ref<HTMLElement | null>(null)

    const onClick = () => {
      if (!rootElRef.value) {
        return
      }

      pushCta(
        {
          ctaLabel: props.content.title,
          clickText: props.content.link.label,
          ctaType: 'moodboard_builder',
          ctaUrl: props.content.link.url,
        },
        rootElRef.value
      )
    }

    return {
      rootElRef,
      onClick,
    }
  },
})
